import { graphql, useStaticQuery } from "gatsby"

import {
  InsuranceWidgetWrapper,
  InsuranceWidgetInnerWrapper,
  InsuranceWidgetTitle,
  InsuranceWidgetSubTitle,
  LearnMoreText,
} from "./styled"

import { InsuranceCarrierSelect } from "./InsuranceCarrierSelect"
import { InsuranceCarrierSelectV2 } from "./InsuranceCarrierSelectV2"
import { InsuranceWidgetPlanStyle, InsuranceWidgetStyle } from "./constants"
import { useRolloutFlag } from "../../../store/rolloutFlag"
import { useEffect } from "react"
import { InsuranceLogos } from "../InsuranceLogos"

const INSURANCE_CSV = graphql`
  query InsuranceCsvQuery {
    prismicActiveInsurancePlans {
      data {
        csv {
          richText
        }
      }
    }
  }
`

export interface InsuranceWidgetProps {
  style?: InsuranceWidgetStyle
}

export const InsuranceWidget = ({
  style = InsuranceWidgetPlanStyle,
}: InsuranceWidgetProps) => {
  const insuranceCsv =
    useStaticQuery(INSURANCE_CSV).prismicActiveInsurancePlans.data.csv.richText
  const { flag, loadFlag } = useRolloutFlag()

  useEffect(() => {
    loadFlag("insurance_rte_release_state_provider_config")
  }, [])

  return (
    <InsuranceWidgetWrapper style={style}>
      <InsuranceWidgetInnerWrapper style={style}>
        <InsuranceWidgetTitle style={style}>
          Yes, we take insurance
        </InsuranceWidgetTitle>
        <InsuranceWidgetSubTitle style={style}>
          We're currently in-network with several insurance providers and are in
          the process of expanding who we work with.
        </InsuranceWidgetSubTitle>
        {flag?.insurance_rte_release_state_provider_config ? (
          <InsuranceCarrierSelectV2 />
        ) : (
          <InsuranceCarrierSelect
            insuranceCsv={insuranceCsv}
            externalSource={style.externalSource}
          />
        )}
        {style.showMiddleLogos ? <InsuranceLogos /> : null}
        <LearnMoreText style={style}>
          Don’t see your state or insurance carrier?{" "}
          <a href="https://forms.cerebral.com/to/KV3bSpek" target="_blank">
            Join waitlist
            <br />
          </a>
          <a
            href="/insurance-partners?external_source=learn-more-widget"
            target="_blank"
          >
            Learn more
          </a>{" "}
          about our insurance policy and ways to pay.
        </LearnMoreText>
      </InsuranceWidgetInnerWrapper>
    </InsuranceWidgetWrapper>
  )
}

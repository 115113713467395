import styled from "styled-components"
import Button from "../../../common/Button"
import Link from "../../../common/Link"
import menuImage from "../../../images/menu.svg"

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`
export const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(165.96deg, #d2eff6 3.32%, #d4dbf5 97.9%);
  padding: 0 15px;

  @media (min-width: 600px) {
    padding: 0 30px;
  }

  @media (min-width: 834px) {
    padding: 0 50px;
  }
`

export const Menu = styled.ul``
export const MenuItem = styled.li`
  font-size: 14px;
  font-weight: 600;
  position: relative;
  a,
  span {
    text-decoration: none;
    color: #250044;
    &:active,
    &:hover {
      color: #6786ff;
    }
  }
  cursor: pointer;

  ${Button} {
    color: #fff;
  }
`
export const MenuLink = styled.a``
export const MenuLinkExternal = styled.a``
export const MobileMenuIcon = styled.div`
  display: block;
  width: 18px;
  height: 14px;
  margin: 15px 0;
  background-image: url(${menuImage});
  background-size: cover;
  cursor: pointer;
  @media (min-width: 834px) {
    display: none;
  }
`
export const Logo = styled.a`
  width: 112px;
  height: 25px;

  @media (min-width: 834px) {
    width: 178px;
    height: 40px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

export const CloseButton = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SubMenu = styled.div`
  background: #ffffff;
  box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
  border-radius: 5px;
  position: absolute;
  max-width: 130px;
  z-index: 2;
`

export const SubMenuItem = styled.div`
  ${MenuLink} {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: ${({ active }) => (active ? "#6786FF" : "#250044")};
    text-decoration: none;
    padding: 12px 12px;
    display: block;
    &:active,
    &:hover {
      color: #6786ff;
    }
  }
`

export const SubMenuBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

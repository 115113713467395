import styled from "styled-components"
import { colors } from "../../themes/colors"
import { BodyRegular } from "../Body/styled"

export const CardContainer = styled.div`
  width: 1175px;
  padding: 32px;
  background: ${colors.c_white[500]};
  border-radius: 8px;
  border: 0.5px solid #a8baff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 8px;
  @media (max-width: 1300px) {
    width: fit-content;
  }
  @media (max-width: 800px) {
    margin: 0;
    border-radius: 0;
    background: transparent;
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: fit-content;
    border: 0.5px #a8baff;
    border-style: solid none;
    max-height: fit-content;
  }
`

export const CarePersonName = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #1c1e21;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-size: 22px;
  line-height: 30px;

  @media (max-width: 499px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const CarePersonBoldText = styled.p`
  font-size: 16px;
  line-height: 20px;
  @media (max-width: 499px) {
    font-family: Inter;
    font-size: 14px;
    line-height: 24px;
  }
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #1c1e21;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  text-overflow: ellipsis;
  overflow: hidden;
`

export const CarePersonImageContainer = styled.div`
  display: flex;
  min-width: 206px;
  flex-direction: row;
  align-items: center;
  & ${CarePersonBoldText} {
    font-weight: 500;
  }
  & ${CarePersonName} {
    margin-top: 24px;
  }
  @media (min-width: 800px) {
    display: flex;
    flex-direction: column;
    & ${CarePersonName} {
      display: none;
    }
    & ${CarePersonBoldText} {
      display: none;
    }
  }
`

export const AcceptingNewClients = styled.div`
  height: 24px;
  font-size: 12px;
  margin: 16px 8px;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: #fbedc5;
  border-radius: 30px;
  text-wrap: nowrap;

  @media (max-width: 800px) {
    margin: 16px 0px;
  }
`

export const MobileWrapper = styled.div`
  @media (max-width: 800px) {
    margin: 8px;
    display: flex;
    flex-direction: column;
  }
`

export const CarePersonBioContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 570px;
  & ${CarePersonName} {
    margin-top: 24px;
  }
  & ${CarePersonBoldText} {
    font-weight: 500;
    margin-bottom: 24px;
  }
  & a {
    max-width: 208px;
  }
  & ${BodyRegular} {
    margin-bottom: 16px;
    max-width: 540px;
  }
  @media (max-width: 800px) {
    width: fit-content;
    order: 3;
    & ${CarePersonName} {
      display: none;
    }
    & ${CarePersonBoldText} {
      display: none;
    }
    & ${BodyRegular} {
      font-size: 14px;
    }
    margin-right: 8px;
  }
`

export const CarePersonDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 240px;
  & > div:first-child {
    margin-top: 16px;
  }
  & ${BodyRegular} {
    color: ${colors.c_secondary[600]};
    font-size: 14px;
    font-weight: 300px;
  }
  @media (max-width: 800px) {
    max-width: 540px;
  }
`

export const CarePersonDetail = styled.div`
  padding-bottom: 16px;
  min-width: 240px;
  @media (max-width: 800px) {
    & ${CarePersonBoldText} {
      font-weight: 500;
    }
  }
`

import { Link } from "gatsby"
import styled from "styled-components"

export const NavDrawerWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 1000px;
  width: 100%;
  height: auto;
  background: #ffffff;

  @media (max-width: 1440px) {
    max-width: 100%;
  }
`

export const TestimonialWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 16px;
  max-width: 1000px;
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 0 24px;

  @media (max-width: 1440px) {
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    padding: 0 40px;
  }
`

export const TestimonialCard = styled.a`
  display: flex;
  padding: 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: #f2f5ff;
  border-radius: 16px;
  max-width: 1000px;
  text-decoration: none;

  &:hover {
    background: #e5ebff;
  }

  &:focus {
    border-color: #385ade;
    background: #e5ebff;
  }

  img {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    align-self: flex-start;
    margin-top: 4px;
    margin-left: -1px;
  }

  @media (min-width: 279px) {
    font-size: 14px;
  }
`

export const TestimonialText = styled.p`
  align-self: stretch;
  color: #1c1e21;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;

  @media (max-width: 414px) and (min-width: 279px) {
    font-size: 14px;
  }
`

export const TestimonialName = styled.p`
  color: #1c1e21;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 768px) and (min-width: 279px) {
    font-size: 14px;
  }
`

import { GetStartedButton } from ".."
import {
  AlternatingSplashContainer,
  GetStartedContainer,
  PrescriberLink,
  SectionBody,
  SectionContainer,
  SectionHeader,
  SplashImage,
} from "./styled"

export interface IAlternatingSplashesCopy {
  topSection: {
    heading: string
    body: {
      heading: string
      description: string
      icon: string
    }[]
    image: string
  }
  bottomSection: {
    heading: string
    body: {
      heading: string
      description: string
      icon: string
    }[]
    image: string
  }
  carePersonTitle: "prescriber" | "therapist"
  btnMaxWidth?: string
}

export interface IAlternatingSplashesProps {
  copy: IAlternatingSplashesCopy
  hideGetStartedBtn?: boolean
  hideFindCarePersonBtn?: boolean
}

export const AlternatingSplashes = ({
  copy,
  hideGetStartedBtn,
  hideFindCarePersonBtn,
}: IAlternatingSplashesProps) => {
  const { topSection, bottomSection, carePersonTitle, btnMaxWidth } = copy
  return (
    <AlternatingSplashContainer>
      <SectionContainer className="top">
        <div>
          <SectionHeader>{topSection.heading}</SectionHeader>
          {topSection.body.map((item, index) => (
            <SectionBody key={index}>
              <img src={item.icon} alt="" />
              <div>
                <h3>{item.heading}</h3>
                {item.heading === "Highly rated" ? (
                  <p>
                    We assess and approve each therapist before they join
                    Cerebral. 99% of our therapists have a{" "}
                    <span style={{ fontWeight: "600" }}>
                      4.8 approval rating or higher
                    </span>{" "}
                    from their clients.
                  </p>
                ) : (
                  <p>{item.description}</p>
                )}
              </div>
            </SectionBody>
          ))}
        </div>
        <div>
          <SplashImage src={topSection.image} alt="" />
        </div>
      </SectionContainer>
      <SectionContainer className="bottom">
        <div>
          <SplashImage src={bottomSection.image} alt="" />
        </div>
        <div>
          <SectionHeader>{bottomSection.heading}</SectionHeader>
          {bottomSection.body.map((item, index) => (
            <SectionBody key={index}>
              <img src={item.icon} alt="" />
              <div>
                <h3>{item.heading}</h3>
                <p>{item.description}</p>
              </div>
            </SectionBody>
          ))}
        </div>
      </SectionContainer>
      {!hideGetStartedBtn && (
        <GetStartedContainer maxWdith={btnMaxWidth}>
          <GetStartedButton height={56} />
        </GetStartedContainer>
      )}
      {!hideFindCarePersonBtn && (
        <GetStartedContainer>
          <PrescriberLink
            href={`/care-team/${carePersonTitle}s`}
            textDecoration="underline"
          >
            Find a {carePersonTitle} in your area
          </PrescriberLink>
        </GetStartedContainer>
      )}
    </AlternatingSplashContainer>
  )
}

import React from "react"

import { SubItemList, SubMenuItem } from "../SubMenu/styled"
import RightChevron from "../images/chevron-right.svg"
import HandFlower from "../images/hand-flower.svg"
import {
  CommunityWrapper,
  ReferAFriend,
  ReferAFriendArrowWrapper,
} from "./styled"
import { SubDivider } from "../Menu/styled"
import { SubNavOptions } from "../types"

const communityItems: SubNavOptions[] = [
  {
    subMenuTitle: "LGBTQIA+",
    link: "https://get.cerebral.com/lgbtqia-mental-health/",
  },
  {
    subMenuTitle: "Military veterans",
    link: "https://get.cerebral.com/heroes/",
  },
]

const Community = () => {
  return (
    <CommunityWrapper>
      <h6>Communities</h6>
      <SubDivider $showOnDesktop />
      <SubItemList>
        {communityItems.map(item => (
          <a href={item.link || "/"} key={item.subMenuTitle}>
            <SubMenuItem key={item.subMenuTitle}>
              <button>{item.subMenuTitle}</button>
              <img src={RightChevron} alt="right chevron arrow" />
            </SubMenuItem>
          </a>
        ))}
      </SubItemList>
      <ReferAFriend href="/refer-v2/">
        <div>
          <img src={HandFlower} alt="gift icon" />
          <p>Refer a friend</p>
        </div>
        <ReferAFriendArrowWrapper>
          <img src={RightChevron} alt="right chevron arrow" />
        </ReferAFriendArrowWrapper>
      </ReferAFriend>
    </CommunityWrapper>
  )
}
export default Community

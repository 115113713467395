import React from "react"
import {
  SubscriptionPopup,
  Buttons,
  StyledImage,
  Backdrop,
  ModalButton,
} from "./styled"
import subscriptionclose from "../../../images/subscriptionclose.svg"
import segment, { segmentEvents } from "../../../lib/segment"

interface StateInsurancePopupProps {
  isBCBModalOpen: boolean
  setIsBCBModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  onBCBNoClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const StateInsurancePopup = ({
  isBCBModalOpen,
  setIsBCBModalOpen,
  onBCBNoClick,
}: StateInsurancePopupProps) => {
  const handleCloseModal = () => {
    setIsBCBModalOpen(false)
  }

  return isBCBModalOpen ? (
    <>
      <Backdrop
        isOpen={isBCBModalOpen}
        onClick={handleCloseModal}
        data-testid="backdrop"
      />
      <SubscriptionPopup isOpen={isBCBModalOpen}>
        <div>
          <StyledImage src={subscriptionclose} onClick={handleCloseModal} />
          <h4>Do you live in Texas and have Blue Cross Blue Shield?</h4>
        </div>
        <Buttons>
          <ModalButton
            onClick={() => {
              segment.track(
                segmentEvents.INSURANCE_MODAL_BCBS_TEXAS,
                {},
                { integrations: { All: false, Heap: true } }
              )
              window.open(
                process.env.GATSBY_START_URL + "&screenHint=signup",
                "_self"
              )
            }}
          >
            Yes
          </ModalButton>
          <ModalButton
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              segment.track(
                segmentEvents.INSURANCE_MODAL_NO_BCBS_TEXAS,
                {},
                { integrations: { All: false, Heap: true } }
              )
              onBCBNoClick(event)
            }}
          >
            No
          </ModalButton>
        </Buttons>
      </SubscriptionPopup>
    </>
  ) : null
}

export default StateInsurancePopup

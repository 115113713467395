import React from "react"

import LeftChevron from "../images/chevron-left.svg"
import RightChevron from "../images/chevron-right.svg"
import CloseIconImg from "../images/close-icon.svg"
import { useState } from "react"
import Community from "../Community"
import { ABOUT_CEREBRAL, INSURANCE, RESOURCES, SERVICES } from "../constants"
import MentalHealthTest from "../MentalHealthTest"
import OurProviders from "../OurProviders"
import { NavigationItem } from "../types"
import {
  BackIcon,
  SubItemList,
  SubMenuHeader,
  SubMenuItem,
  SubMenuWrapper,
} from "./styled"
import { BlogPostPillBody } from "../../../BlogPostPill/BlogPostPill"
import { MobileButtonContainer } from "../../../shared/MobileButtonContainer"
import { NavDrawerTestimonial } from "../../../ui/NavDrawerTestimonial"
import FindAClinician from "../FindAClinician"
import BecomeAProvider from "../BecomeAProvider"

interface SubMenuProps {
  closeMobileMenu: () => void
  closeSubMenu: () => void
  subMenuData: NavigationItem | null
  isOpen: boolean
  disableTransition: boolean
  shouldPadTop: boolean
}

export const SubMenu = ({
  closeMobileMenu,
  closeSubMenu,
  subMenuData,
  isOpen,
  disableTransition,
  shouldPadTop,
}: SubMenuProps) => {
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState<string | null>(
    null
  )

  const handleSubMenuItemClick = (itemTitle: string) => {
    if (selectedSubMenuItem === itemTitle) {
      closeMobileMenu()
      return
    }

    if (!selectedSubMenuItem) {
      setSelectedSubMenuItem(itemTitle)
      closeMobileMenu()
      return
    }
  }

  return (
    <SubMenuWrapper
      $shouldPadTop={shouldPadTop}
      $isOpen={isOpen}
      $disableTransition={disableTransition}
    >
      <SubMenuHeader>
        <BackIcon onClick={closeSubMenu}>
          <img src={LeftChevron} alt="left chevron arrow" />
          <p>{subMenuData?.mainMenuTitle}</p>
        </BackIcon>
        <img
          src={CloseIconImg}
          alt="close icon"
          onClick={() => closeMobileMenu()}
        />
      </SubMenuHeader>

      <SubItemList>
        {subMenuData?.subMenuOptions.map(item => (
          <div key={item.subMenuTitle}>
            {item.link ? (
              <a
                href={item.link}
                onClick={() => handleSubMenuItemClick(item.subMenuTitle)}
                tabIndex={-1}
              >
                <SubMenuItem key={item.subMenuTitle}>
                  <button>{item.subMenuTitle}</button>
                  {item.link && (
                    <img src={RightChevron} alt="right chevron arrow" />
                  )}
                </SubMenuItem>
              </a>
            ) : (
              <SubMenuItem key={item.subMenuTitle} $noLink>
                <button disabled={true}>{item.subMenuTitle}</button>
              </SubMenuItem>
            )}

            {item.additionalComponent?.type === "Testimonial" && (
              <NavDrawerTestimonial
                servicePlan={item.additionalComponent.testimonialType}
              />
            )}
            {item.additionalComponent?.type === "Pill" && <BlogPostPillBody />}
          </div>
        ))}
      </SubItemList>

      {subMenuData?.mainMenuTitle === SERVICES && (
        <>
          <FindAClinician />
        </>
      )}
      {subMenuData?.mainMenuTitle === INSURANCE && (
        <>
          <OurProviders />
        </>
      )}
      {subMenuData?.mainMenuTitle === ABOUT_CEREBRAL && (
        <>
          <BecomeAProvider />
        </>
      )}
      {/* MentalHealthTest, Community section should only live on the resources submenu */}
      {subMenuData?.mainMenuTitle === RESOURCES && (
        <>
          <MentalHealthTest />
          <Community />
        </>
      )}
      {isOpen && <MobileButtonContainer />}
    </SubMenuWrapper>
  )
}

import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { FlexWrapper } from "./LandingPageStyles"
import { InsuranceCard } from "../../components/shared/InsuranceCard"

const Section = styled(FlexWrapper)<{ background?: string }>`
  background: ${props =>
    props.background || "linear-gradient(135deg, #eef8f9 0%, #fef9ec 100%)"};
  padding: 64px 0;

  @media (max-width: 1040px) {
    padding: 48px 24px 48px 24px;
  }
`

const ContentWrapper = styled.div`
  width: 992px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  margin-top: 32px;

  @media (max-width: 1040px) {
    width: 480px;
    grid-template-columns: 1fr;
    gap: 32px;
    margin-top: 28px;
  }

  @media (max-width: 528px) {
    width: 100%;
  }
`

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: start;

  @media (max-width: 1040px) {
    text-align: center;
    gap: 16px;
  }
`

const LeftTitle = styled.h2`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 1040px) {
    font-size: 24px;
    line-height: 28px;
    max-width: 320px;
    margin: 0 auto;
  }
`

const LeftDescription = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  p:not(:first-child) {
    margin-top: 24px;
  }
`

const RightSide = styled.div``

const LogoWrapper = styled.div`
  width: 992px;

  @media (max-width: 1040px) {
    width: 480px;
  }

  @media (max-width: 528px) {
    width: calc(100vw - 48px);
  }
`

const Logo = styled.img`
  width: 133px;
`

interface InsuranceCardWithTextLeftInterface {
  slice: {
    primary: {
      background_color: string
      body_card_title: {
        text: string
      }
      header_card_icon: {
        url: string
      }
      header_card_text: {
        text: string
      }
      logo: {
        url: string
      }
      price: {
        text: string
      }
      section_description: {
        html: string
      }
      section_title: {
        text: string
      }
      supporting_text: {
        text: string
      }
    }
  }
}

const InsuranceCardWithTextLeft = ({
  slice,
}: InsuranceCardWithTextLeftInterface) => {
  const {
    background_color,
    body_card_title,
    header_card_icon,
    header_card_text,
    logo,
    price,
    section_description,
    section_title,
    supporting_text,
  } = slice.primary

  return (
    <Section background={background_color}>
      <LogoWrapper>
        <Logo src={logo.url} alt="logo" />
      </LogoWrapper>
      <ContentWrapper>
        <LeftSide>
          <LeftTitle>{section_title.text}</LeftTitle>
          <LeftDescription
            dangerouslySetInnerHTML={{ __html: section_description.html }}
          />
        </LeftSide>
        <RightSide>
          <InsuranceCard
            icon={header_card_icon.url}
            headerText={header_card_text.text}
            bodyTitle={body_card_title.text}
            price={price.text}
            supportingText={supporting_text.text}
          />
        </RightSide>
      </ContentWrapper>
    </Section>
  )
}

export const query = graphql`
  fragment PageInsuranceCardWithTextLeftSlice on PrismicPageDataBodyInsuranceCardWithTextLeft {
    id
    slice_type
    primary {
      background_color
      body_card_title {
        text
      }
      header_card_icon {
        url
      }
      header_card_text {
        text
      }
      logo {
        url
      }
      price {
        text
      }
      section_description {
        html
      }
      section_title {
        text
      }
      supporting_text {
        text
      }
    }
  }
`

export default InsuranceCardWithTextLeft

import styled from "styled-components"

export const CarePersonHeadingContainer = styled.section`
  background: transparent;
  display: flex;
  width: 100%;
  padding: 0 0px 25px;
  & > div {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 40px;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      padding: 88px 0px 28px;
    }
  }
`

export const CarePersonHeadingContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 13px;
  }

  @media (max-width: 600px) {
    order: 2;
    text-align: center;
    min-height: 142px;
    margin-top: 16px;
  }
`

export const RoleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (max-width: 600px) {
    justify-content: center;
    flex-direction: column;
  }
`

export const Role = styled.span`
  color: #393939;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const AcceptingWrapper = styled.div`
  display: flex;

  & > div {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 600px) {
    justify-content: center;
  }
`

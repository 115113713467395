import { H1 } from "../../../ui"
import gendersvg from "../../../../images/Therapists-bio/gender-nuetral.svg"
import {
  AcceptingWrapper,
  CarePersonHeadingContainer,
  CarePersonHeadingContentContainer,
  Role,
  RoleWrapper,
} from "./styled"
import { InnerContentContainer } from "../../styled"
import { ICarePerson } from "../../types"
import { AcceptingNewClients } from "../../../../components/ui/CarePersonNearMeCard/styled"

interface Heading {
  hasCarePersonInfo: boolean
  carePersonData: ICarePerson
}
export const Heading = ({
  hasCarePersonInfo,
  carePersonData: carePersonData,
}: Heading): JSX.Element => {
  return (
    <CarePersonHeadingContainer>
      <InnerContentContainer showBorder={!!hasCarePersonInfo}>
        <CarePersonHeadingContentContainer>
          <H1>{carePersonData.name}</H1>
          <RoleWrapper>
            <Role>{`${carePersonData.role} | ${carePersonData.degrees.join(
              ", "
            )}`}</Role>
          </RoleWrapper>
          <AcceptingWrapper>
            {carePersonData.accepting_new_client && (
              <AcceptingNewClients>Accepting new clients</AcceptingNewClients>
            )}
          </AcceptingWrapper>
        </CarePersonHeadingContentContainer>
        <img
          alt=""
          src={
            carePersonData.profile_photo === null ||
            carePersonData.profile_photo === ""
              ? gendersvg
              : carePersonData.profile_photo
          }
          height={173}
          width={173}
          loading="lazy"
        />
      </InnerContentContainer>
    </CarePersonHeadingContainer>
  )
}

import {
  InsuranceLogoContainer,
  InsuranceLogoWrapper,
  LogoImage,
} from "./styled"
import CignaIcon from "../../../images/cigna.png"
import UnitedHealthcareIcon from "../../../images/united-healthcare.png"
import OptumIcon from "../../../images/optum.png"
import HumanaIcon from "../../../images/humana.png"

interface InsuranceLogosProps {
  backgroundColor?: string
}

export const InsuranceLogos = ({ backgroundColor }: InsuranceLogosProps) => {
  return (
    <InsuranceLogoContainer backgroundColor={backgroundColor}>
      <InsuranceLogoWrapper>
        <LogoImage src={CignaIcon} alt="Cigna Insurance Logo" />
        <LogoImage src={HumanaIcon} alt="Humana Insurance Logo" />
        <LogoImage src={OptumIcon} alt="Optum Insurance Logo" />
        <LogoImage
          src={UnitedHealthcareIcon}
          alt="United Healthcare Insurance Logo"
        />
      </InsuranceLogoWrapper>
    </InsuranceLogoContainer>
  )
}

import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useRolloutFlag } from "../../../../../store/rolloutFlag"
import { LetsStayInTouch } from "../LetsStayInTouch"
import {
  LegitScriptLogoWrapper,
  MenuColumn,
  MenuTitle,
  MenuWrapper,
  SubMenuWrapper,
} from "./styled"

const FOOTER_QUERY = graphql`
  query FooterColumnsQuery {
    allPrismicFooter(sort: { fields: data___order }) {
      edges {
        node {
          data {
            title
            sub_menu {
              external
              sub_menu_title
              to
            }
            order
          }
        }
      }
    }
  }
`
interface FooterQueryResponse {
  allPrismicFooter: {
    edges: {
      node: {
        data: {
          title: string
          sub_menu: {
            external: boolean
            sub_menu_title: string
            to: string
          }[]
          order: number
        }
      }
    }[]
  }
}

export const Menu = (): JSX.Element => {
  const response: FooterQueryResponse = useStaticQuery(FOOTER_QUERY)
  const { flag, loadFlag } = useRolloutFlag()

  return (
    <MenuWrapper>
      <LetsStayInTouch />
      {response.allPrismicFooter.edges.map(column => {
        const menu = column.node.data
        return (
          <MenuColumn key={menu.title}>
            <div>
              <MenuTitle>{menu.title}</MenuTitle>
              <SubMenuWrapper>
                {menu.sub_menu.map(sub => {
                  if (sub.external) {
                    return (
                      <a href={sub.to} key={sub.sub_menu_title}>
                        {sub.sub_menu_title}
                      </a>
                    )
                  }
                  return (
                    <a href={sub.to} key={sub.sub_menu_title}>
                      {sub.sub_menu_title}
                    </a>
                  )
                })}
                {menu.title === "Legal" && (
                  <button
                    key="sharing-settings"
                    onClick={() => {
                      ;(window as any).ketch("showPreferences", {
                        showOverviewTab: false,
                        tab: "consentsTab",
                      })
                    }}
                  >
                    Data Deletion Requests and Sharing Settings
                  </button>
                )}
                {menu.title === "Legal" && (
                  <LegitScriptLogoWrapper>
                    <a
                      href="https://www.legitscript.com/websites/?checker_keywords=cerebral.com"
                      target="_blank"
                      title="Verify LegitScript Approval for www.cerebral.com"
                    >
                      <img
                        src="https://static.legitscript.com/seals/4647846.png"
                        alt="Verify Approval for www.cerebral.com"
                        width={73}
                        height={79}
                      />
                    </a>
                  </LegitScriptLogoWrapper>
                )}
              </SubMenuWrapper>
            </div>
          </MenuColumn>
        )
      })}
    </MenuWrapper>
  )
}

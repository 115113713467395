import React from "react"

import RightChevron from "../images/chevron-right.svg"
import CloseIconImg from "../images/close-icon.svg"
import logoOnlyImage from "../images/logo-only.svg"
import logoImage from "../images/new-logo.svg"
import { useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { navigationItems } from "../constants"
import { SubMenu } from "../SubMenu"
import { ItemListWrapper, MobileHeader } from "../SubMenu/styled"
import { Options } from "../types"
import {
  DesktopMenuWrapper,
  Divider,
  Item,
  ItemList,
  Logo,
  SubMenuBackground,
} from "./styled"
import { useWindowSize } from "../../../hooks"
import { MobileButtonContainer } from "../../../shared/MobileButtonContainer"

interface MenuProps {
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: (isOpen: boolean) => void
}

const Menu = ({ isMobileMenuOpen, setIsMobileMenuOpen }: MenuProps) => {
  const [selectedOption, setSelectedOption] = useState<Options | null>(null)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [disableTransition, setDisableTransition] = useState(false)
  const [shouldPadTop, setShouldPadTop] = useState(true)
  const nodeRef = useRef(null)

  useEffect(() => {
    const handleShouldPadTop = () =>
      setShouldPadTop(window.scrollY ? false : true)

    window.addEventListener("scrollend", handleShouldPadTop)

    return () => {
      window.removeEventListener("scrollend", handleShouldPadTop)
    }
  }, [])

  const handleMenuItemClick = (menuItemSelected: Options) => {
    if (selectedOption === menuItemSelected) {
      setSelectedOption(null)
      return
    }

    if (!selectedOption) {
      setSelectedOption(menuItemSelected)
      return
    }

    // the below block executes if a menu item is selected and the user clicks a different one
    setIsTransitioning(true)
    setSelectedOption(null)
    setTimeout(() => {
      setSelectedOption(menuItemSelected)
      setIsTransitioning(false)
    }, 300)
  }

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false)
    setSelectedOption(null)
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setSelectedOption(null)
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => window.removeEventListener("keydown", handleKeyDown)
  }, [])

  useEffect(() => {
    let resizeTimer: NodeJS.Timeout
    const resizeListener = () => {
      setDisableTransition(true)
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        setDisableTransition(false)
      }, 400)
    }

    window.addEventListener("resize", resizeListener)

    return () => window.removeEventListener("resize", resizeListener)
  }, [])

  const renderItemList = () => {
    return (
      <ItemList>
        {navigationItems.map(item => (
          <Item
            key={item.mainMenuTitle}
            $anySelectedItems={!!selectedOption}
            $isSelected={selectedOption === item.mainMenuTitle}
            onClick={() => handleMenuItemClick(item.mainMenuTitle)}
          >
            <button>{item.mainMenuTitle}</button>
            <img src={RightChevron} alt="right chevron arrow" />
          </Item>
        ))}
      </ItemList>
    )
  }

  const { isMobile } = useWindowSize(768)

  return (
    <>
      {/* mobile main menu */}

      <ItemListWrapper
        $isOpen={isMobileMenuOpen}
        $disableTransition={disableTransition}
      >
        <MobileHeader>
          <Logo href="/">
            <img
              src={isMobile ? logoOnlyImage : logoImage}
              alt="Cerebral logo"
            />
          </Logo>
          <img
            src={CloseIconImg}
            alt="close icon"
            onClick={() => closeMobileMenu()}
          />
        </MobileHeader>
        <Divider />
        {renderItemList()}
        {isMobileMenuOpen && <MobileButtonContainer />}
      </ItemListWrapper>

      {/* desktop main menu */}
      <DesktopMenuWrapper>{renderItemList()}</DesktopMenuWrapper>

      {navigationItems.map(item => (
        <SubMenu
          shouldPadTop={shouldPadTop}
          key={item.mainMenuTitle}
          closeMobileMenu={() => closeMobileMenu()}
          closeSubMenu={() => setSelectedOption(null)}
          subMenuData={item}
          isOpen={selectedOption === item.mainMenuTitle}
          disableTransition={disableTransition}
        />
      ))}

      {/* this background overlays the page as the submenu is open */}
      <CSSTransition
        in={!!selectedOption || isTransitioning}
        timeout={300}
        classNames="fade"
        unmountOnExit
        nodeRef={nodeRef}
      >
        <SubMenuBackground onClick={() => setSelectedOption(null)} />
      </CSSTransition>
    </>
  )
}

export default Menu
